@import "/variables";
@import "/typography";

// buttons 
.bordered_green_btn {
    border: 1px solid $primary_50;
    background: #fff;
    color: $primary_50;
    padding: 0.7rem 0rem;
    border-radius: 0.7rem;
    @extend .buttons_large;
    cursor: pointer;
    padding: 0.5rem 1.2rem;     
}

.primary_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: $primary_50 ;
    border-radius: 0.7rem;
    color: #fff;
    border: unset;
    cursor: pointer;
    padding: 0.5rem 1.2rem;
}

// Inputs 
.styled_input {
    font-family: 'Roboto';
    background-color: #F0F2F2;
    border-radius: 12px;
    max-width: 33.75rem;
    margin-top: unset;
    padding-left: 0.5rem;
    font-weight: 400
}

.styled_input_wrapper  {
    align-items: flex-start;
    & input {
        @extend .styled_input;
        margin-top: 0.36rem;
        width: -webkit-fill-available;
    }
    & label{
        @extend .body_small_regular;
        padding-left: 0.1rem;
    }
}

.feint_shadow{
    box-shadow: 0px 0px 6px rgba(19, 31, 30, 0.1);
}