@import "src/GlobalStyles/typography";
@import "src/GlobalStyles/variables";

.container {
    width: auto;
    .divider {
        width: 1.5rem;
        height: 1px;
        margin: 0rem 0.75rem;
        border-bottom: 1px solid $grey10;
    }
    & > div:last-child{
        display: none;
    }
}

.sectionContainer {
    margin: 0rem 0.1rem;
    display: flex;
    align-items: center;
    @extend .body_medium_regular;
    & div {
        &:first-child{
            border-radius: 100%;
            background-color:$grey5;
            min-height: 2rem;
            min-width: 2rem;
            margin-right: 0.75rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &.active {
        & div {
            &:first-child{
                background-color: $primary_50;
                color: #fff;
            }
            &:last-child {
                color: $primary_50;
            }
        }
    }

}