$white: #ffffff;
$whiteShadow: rgba(0, 0, 0, 0.1);
$primary1: rgba(38, 167, 153, 1);
$primary2: rgba(51, 81, 80, 1);
$newBlack: rgba(38, 50, 56, 1);
$Accents1: rgba(168, 181, 180, 1);
$secondary1: #a7a9ac;
$secondary2: rgba(51, 81, 80, 1);
$secondaryWithOpacity: rgba(167, 169, 172, 0.1);
$primaryLinearGradient: linear-gradient(
  90deg,
  #26a799 0%,
  #22b87f 99.99%,
  #19dd43 100%
);
$primary_50: #36796f;
$grey10: #e7e9e9;
$grey5: #f0f2f2;

$Geogrotesque-SemiBold: Geogrotesque-SemiBold;
$AvenirLTStd-Light: AvenirLTStd-Light;
$AvenirLTStd: Avenir LT Std;
$Geogrotesque-Regular: Geogrotesque-Regular;

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: Candara;
  src: url("../assets/fonts/Candara.ttf");
}

@font-face {
  font-family: Geogrotesque-SemiBold;
  src: url("../assets/fonts/Geogrotesque-SemiBold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: AvenirLTStd-Light;
  src: url("../assets/fonts/AvenirLTStd-Light.otf");
  font-weight: bold;
}

@font-face {
  font-family: Avenir LT Std;
  src: url("../assets/fonts/AvenirLTStd-Roman.otf");
  font-weight: bold;
}

@font-face {
  font-family: Geogrotesque-Regular;
  src: url("../assets/fonts/Geogrotesque-Regular.ttf");
}
