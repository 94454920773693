// buttons 
.buttons_large {
    /* buttons/large */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.body_small_regular {
    /* body/small – regular */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 168%;
    color: #131F1E;
}

.body_medium_regular {
    /* body/medium – regular */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.body_medium_medium {
    /* body / medium – medium */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.body_large_medium {
    /* body/large – medium */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}

.body_xlarge_medium {
    /* body/xlarge – medium */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
}

.body_xxlarge_semibold {
    /* body/xxlarge - semibold */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 132%;
}

.headline_h3 {
    /* headline / H3 */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 132%;
}