html,body{
    width: 100%;
    overflow-x: hidden;
}
@import "/variables";

@mixin button {
    border-radius: 4em;
    font-family: $Geogrotesque-SemiBold;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.15em;
    outline: none;
    cursor: pointer;
    border: none;
    padding: 1.07em 2.7em;
    text-transform: uppercase;
}

@mixin link {
    color: $white;
    text-decoration: none;
}

@mixin input {
    margin: 0;
    height: 3em;
    background-color: rgba(255, 255, 255, 0.9);
    outline: none;
    color: $primary2;
    font-size: 1em;
    font-family: $Geogrotesque-SemiBold;
    font-weight: 600;
    letter-spacing: 0.1em;
    border-radius: 0.6em;
    border: none;
    text-align: left;
    padding-left: 4%;
    width: 98%;
    margin-top: 1em;

    &::placeholder {
        opacity: 0.5;
    }
}

@mixin phone {
    @media (max-width:  540px) {
        @content;
    }
}

@mixin desktopOnly {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin smallDesktopOnly {
    @media screen and (max-width: 412px),
    screen and (max-height: 732px) {
        @content;
    }
}

body {
    overflow-x: hidden;
}

html {
    overflow-x: hidden;
}